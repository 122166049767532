@import 'scss/main';

.color-text-blue {
  color: #0063bf !important;
}

.col-5 {
  width: 41.666667%;
  text-align: right;
}

.col-7 {
  width: 58.333333%;
  text-align: left;
}
